var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isLoading)?_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.name'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.description'))+" ")]),_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.active'))+" ")]),(_vm.permisos.includes('role:edit') || _vm.permisos.includes('role:destroy') || _vm.permisos.includes('*'))?_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.actions'))+" ")]):_vm._e()])]),_c('tbody',_vm._l((_vm.items),function(iten,index){return _c('tr',{key:index},[_c('td',[_vm._v(" "+_vm._s(iten.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(iten.description)+" ")]),_c('td',[_c('v-switch',{attrs:{"disabled":!_vm.permisos.includes('role:edit') &&
                  !_vm.permisos.includes('*') &&
                  (iten.user_create_id !== _vm.user.id || iten.user_create_id !== _vm.user.id_father)},on:{"change":function($event){return _vm.editActive(iten)}},model:{value:(iten.active),callback:function ($$v) {_vm.$set(iten, "active", $$v)},expression:"iten.active"}})],1),(_vm.permisos.includes('role:edit') || _vm.permisos.includes('role:destroy') || _vm.permisos.includes('*'))?_c('td',{attrs:{"width":"10%"}},[(
                (_vm.permisos.includes('role:edit') || _vm.permisos.includes('*')) &&
                  (iten.user_create_id === _vm.user.id || iten.user_create_id === _vm.user.id_father)
              )?_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(iten)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSquareEditOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.edit')))])]):_vm._e(),(
                (_vm.permisos.includes('role:destroy') || _vm.permisos.includes('*')) &&
                  (iten.user_create_id === _vm.user.id || iten.user_create_id === _vm.user.id_father)
              )?_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(iten)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])]):_vm._e()],1):_vm._e()])}),0)]},proxy:true}],null,false,224777493)}):_vm._e(),_c('v-card-text',{staticClass:"pt-2"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-end",attrs:{"lg":"12","cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":"5"},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}})],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"350px"},model:{value:(_vm.isDialogVisibleDelete),callback:function ($$v) {_vm.isDialogVisibleDelete=$$v},expression:"isDialogVisibleDelete"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(((_vm.$t('btn.delete')) + " " + (_vm.item.name))))]),_c('v-divider'),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('msg.deleteItem', { item: _vm.item.name })))]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isDialogVisibleDelete = !_vm.isDialogVisibleDelete}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.loading},on:{"click":function($event){return _vm.confirmDelete()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }