<template>
  <div>
    <v-simple-table v-if="!isLoading">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              {{ $t('lbl.name') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.description') }}
            </th>
            <th class="text-uppercase">
              {{ $t('lbl.active') }}
            </th>
            <th
              v-if="permisos.includes('role:edit') || permisos.includes('role:destroy') || permisos.includes('*')"
              class="text-uppercase"
            >
              {{ $t('lbl.actions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(iten, index) in items"
            :key="index"
          >
            <td>
              {{ iten.name }}
            </td>
            <td>
              {{ iten.description }}
            </td>
            <td>
              <v-switch
                v-model="iten.active"
                :disabled="
                  !permisos.includes('role:edit') &&
                    !permisos.includes('*') &&
                    (iten.user_create_id !== user.id || iten.user_create_id !== user.id_father)
                "
                @change="editActive(iten)"
              ></v-switch>
            </td>
            <td
              v-if="permisos.includes('role:edit') || permisos.includes('role:destroy') || permisos.includes('*')"
              width="10%"
            >
              <v-tooltip
                v-if="
                  (permisos.includes('role:edit') || permisos.includes('*')) &&
                    (iten.user_create_id === user.id || iten.user_create_id === user.id_father)
                "
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="editItem(iten)"
                  >
                    <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.edit') }}</span>
              </v-tooltip>

              <v-tooltip
                v-if="
                  (permisos.includes('role:destroy') || permisos.includes('*')) &&
                    (iten.user_create_id === user.id || iten.user_create_id === user.id_father)
                "
                top
                color="error"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItem(iten)"
                  >
                    <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.delete') }}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-card-text class="pt-2">
      <v-row>
        <v-col
          lg="12"
          cols="12"
          class="d-flex justify-end"
        >
          <v-pagination
            v-model="pagination.current"
            :length="pagination.total"
            total-visible="5"
            @input="onPageChange"
          ></v-pagination>
        </v-col>
      </v-row>
    </v-card-text>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="350px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')} ${item.name}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.deleteItem', { item: item.name }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiFormatListText,
} from '@mdi/js'

export default {
  data() {
    return {
      isLoading: true,
      search: '',
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiFormatListText,
      },
      isDialogVisible: false,
      isDialogVisibleDelete: false,
      itemId: null,
      item: {},
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: sessionStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
      loading: false,
    }
  },
  mounted() {
    this.profile()
    this.load()
  },
  methods: {
    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.axios
        .get(
          `role?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${
            this.search
          }&perfil_slug=${sessionStorage.getItem('perfil')}`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page
          }
        })
    },
    onPageChange() {
      this.load()
    },
    showModal(item) {
      this.item = item
      this.isLoad = true
      if (item.permisos !== null) {
        this.abilities = item.permisos.abilities
      }
      this.loadPermisos()
      this.isDialogVisible = !this.isDialogVisible
    },
    loadPermisos() {
      this.abilityList = []
      this.axios
        .get('permiso?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const items = res.data.data
          // eslint-disable-next-line no-plusplus
          for (let indexA = 0; indexA < this.abilities.length; indexA++) {
            const ability = this.abilities[indexA]
            if (ability === '*') {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                this.abilityList.push(element.name)
              }
            } else {
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < items.length; index++) {
                const element = items[index]
                if (element.ability === ability) {
                  this.abilityList.push(element.name)
                }
              }
            }
          }
          // eslint-disable-next-line no-return-assign
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoad = false))
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      sessionStorage.removeItem('role-id')
      this.$router.push({ name: 'role-update' })
    },
    editItem(iten) {
      sessionStorage.setItem('role-id', iten.id)
      this.$router.push({ name: 'role-update' })
    },
    editActive(iten) {
      this.axios
        .post(
          `role/active/${iten.id}`,
          { active: iten.active },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          }
        })
    },

    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    confirmDelete() {
      this.axios
        .delete(`role/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 402) {
              this.$toast.error(this.$t('msg.notDeleteItem'))
            }
          } else {
            this.load()
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisible = false
      this.isDialogVisibleDelete = false
      this.item = {}
      this.itemId = null
      this.loading = false
      this.load()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
