<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <RolesList></RolesList>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import RolesList from './tablas/RolesList.vue'

export default {
  components: {
    RolesList,
  },
}
</script>
